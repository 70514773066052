/**
 * This middleware redirects logged-in users away from pages like login, register etc
 */

export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useAuth()

  if (auth.loggedIn) {
    if (auth.me?.isOnboarded) {
      return navigateTo('/dashboard/')
    }
    else {
      return navigateTo('/onboarding/')
    }
  }
})
